import React from 'react'
import Helmet from "react-helmet"
import styled from 'styled-components';



const TestPage1 = props => {




    return (
        <Wrapper>


            <div className='content'>

                <img src='/images/happy.gif' width='200' style={{ marginBottom: 24 }} />

                <video width="320" height="240" controls>
                    <source src="https://www.sam-huang.info/files/sample.mp4" type="video/mp4"></source>
                </video>

                <div style={{ width: 1, height: 50 }}></div>

                <iframe width="280" height="315" src="https://www.youtube.com/embed/rKtCERFPOQM?si=cbqmmjvhOAFBL4ZG" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>


            </div>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    min-height: 98vh;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .action {
        display: flex;
        flex-direction: row;
        //padding: 15px;
        width:100%;
        height:64px;
        background-color: #EEEEEE;
    }

    & > .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex:1;
        width:100%;
        padding-top:50px;
        padding-bottom:50px;
        //background-color: #FEFCF3;
    }
`



// ---



export default TestPage1;
